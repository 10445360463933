import "./ThirdPage.scss"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
type ChildProps = {};

export const ThirdPage = forwardRef((props: ChildProps, ref) => {
    const [settings, setSettings] = useState({
        accessibility: false,
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: window.innerWidth > 750 ? 4 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    });
    const tags = ['Filters Out Bots','Fixed Participation Cost','Block Lottery Ticket']
    // 监听窗口尺寸变化的 hook
    useEffect(() => {
        const handleResize = () => {
            setSettings((prevSettings) => ({
                ...prevSettings,
                slidesToShow: window.innerWidth > 750 ? 4 : 3, // 动态更新显示数量
            }));
        };

        // 添加窗口尺寸变化的监听器
        window.addEventListener("resize", handleResize);

        // 组件卸载时移除监听器
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useImperativeHandle(ref, () => ({
        startAnimation() {
            const element: any = document.querySelector('.third-page');
            element.classList.add('third-page-start')
            const element1: any = document.querySelector('.third-animate-1');
            const element2: any = document.querySelector('.third-animate-2');
            const element3: any = document.querySelector('.third-animate-3');
            if (element1) {
                void element.offsetWidth;
                element1.classList.add('animate__flipInX');
                element2.classList.add('animate__fadeInUp');
                element3.classList.add('animate__fadeIn');
                element1.style.opacity = 1
                element2.style.opacity = 1
                element3.style.opacity = 1
            }
        },
        removeAnimation(){
            const element: any = document.querySelector('.third-page');
            element.classList.remove('third-page-start')
            const element1: any = document.querySelector('.third-animate-1');
            const element2: any = document.querySelector('.third-animate-2');
            const element3: any = document.querySelector('.third-animate-3');
            if (element1) {
                void element.offsetWidth;
                element1.classList.remove('animate__flipInX');
                element2.classList.remove('animate__fadeInUp');
                element3.classList.remove('animate__fadeIn');
                element1.style.opacity = 0
                element2.style.opacity = 0
                element3.style.opacity = 0
            }
        }
    }))
    return (
        <div className={'third-page'}>
            <div className={'title third-animate-1 animate__animated'}>
                <div className={'text'}>
                    Consensus Algorithm: Analysoor - Ensures Fair
                    Asset Distribution and Liquidity Guidance
                </div>
            </div>
            <div className={'tag-container third-animate-2 animate__animated'}>
                {tags.map((item) => (
                    <div key={item} className={'tag'}>
                        <div className={'icon'}></div>
                        {item}
                    </div>
                ))}
            </div>
            <div className={'footer-container third-animate-3 animate__animated'}>
                <div className={'first-animation'}>
                    <div className={'left-border'}>
                        <div className={'border'}></div>
                        {[...Array(7)].map((_, index) => (
                            <div key={index} className={`point point${index+1}`}>
                                <div className={'tail'}></div>
                            </div>
                        ))}
                    </div>
                    <div className={'center-box'}>
                        <div className={'avatar-banner'}></div>
                        <div className={'avatar-banner avatar-banner-mask'}></div>
                    </div>
                    <div className={'left-border right-border'}>
                        <div className={'border'}></div>
                        {[...Array(7)].map((_, index) => (
                            <div key={index} className={`point point${index+1}`}>
                                <div className={'tail'}></div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="second-animation">
                    <div className={'block-container'}>
                        <div className={'block-box'}>
                            <Slider {...settings} key={JSON.stringify(settings)}>
                                {
                                    [...Array(5)].map((_, index) => (
                                        <div key={index} className={`block-item ${index === 2 ? 'block-item-active' : ''}`}>
                                            <div className={'label'}>
                                                Block
                                            </div>
                                            <div className={'value'}>
                                                <span>Txn Hash 1</span><br/>
                                                <span>Txn Hash 2</span><br/>
                                                <span>Txn Hash 3</span><br/>
                                                ...
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                        <div className={'border-solid'}></div>
                        <div className={'border-solid-2'}></div>
                        <div className={'border-solid-3'}></div>
                        <div className={'sliding-block'}><div className="tail"></div></div>
                        <div className={'sliding-block-2'}><div className="tail"></div></div>
                        <div className={'sliding-block-3'}><div className="tail"></div></div>
                    </div>
                </div>
            </div>
        </div>
    )
})