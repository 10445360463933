import "./FourthPage.scss"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

type ChildProps = {};
export const FourthPage= forwardRef((props: ChildProps, ref) => {
    const tags = ['Gamfied Mining','Fair Launch Participation']
    useImperativeHandle(ref, () => ({
        startAnimation() {
            const element: any = document.querySelector('.fourth-animate-1');
            const element2: any = document.querySelector('.fourth-animate-2');
            const element3: any = document.querySelector('.fourth-animate-3');
            if (element) {
                void element.offsetWidth;
                element.classList.add('animate__bounceInLeft')
                element2.classList.add('animate__flipInX');
                element3.classList.add('animate__fadeInUp');
                element.style.opacity = 1
                element2.style.opacity = 1
                element3.style.opacity = 1
            }
        },
        removeAnimation() {
            const element: any = document.querySelector('.fourth-animate-1');
            const element2: any = document.querySelector('.fourth-animate-2');
            const element3: any = document.querySelector('.fourth-animate-3');
            if (element) {
                element.classList.remove('animate__bounceInLeft');
                element2.classList.remove('animate__flipInX');
                element3.classList.remove('animate__fadeInUp');
                element.style.opacity = 0
                element2.style.opacity = 0
                element3.style.opacity = 0
                void element.offsetWidth;
            }
        }
    }));
    const [innerWidth, setInnerWidth] = useState(0)
    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };
        setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={'fourth-page'}>
            <div className={'left-container fourth-animate-1 animate__animated'}>
                <div className={'frame-container'}>
                    <div className={'icon-1'}>
                        <div className={'container'}>
                            <div className={'label'}>Mining{innerWidth > 750 ? <>&nbsp;</> : <br/>}manufacturers</div>
                        </div>
                    </div>
                    <div className={'icon-2'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Miners
                            </div>
                        </div>
                    </div>
                    <div className={'icon-3'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Mining&nbsp;Rig{innerWidth > 750 ? <>&nbsp;</> : <br/>}Renters
                            </div>
                        </div>
                    </div>
                    <div className={'icon-4'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Mining&nbsp;Pool<br/>Service&nbsp;Providers
                            </div>
                        </div>
                    </div>
                    <div className={'icon-5'}>
                        <div className={'container'}>
                            <div className={'label'}>
                                Analysoor
                            </div>
                        </div>
                    </div>
                    <div className={'label-1'}>Buy</div>
                    <div className={'label-2'}>Sell</div>
                    <div className={'label-3'}>Lease</div>
                    <div className={'label-4'}>Join</div>
                    <div className={'label-5'}>Participate</div>
                    <div className={'arrow-1'}><div className="tail"></div></div>
                    <div className={'arrow-2'}><div className="tail"></div></div>
                    <div className={'arrow-3'}><div className="tail"></div></div>
                    <div className={'arrow-4'}><div className="tail"></div></div>
                    <div className={'arrow-5'}><div className="tail"></div></div>
                    <div className={'arrow-6'}><div className="tail"></div></div>
                </div>
            </div>
            <div className={'right-container'}>
                <div className={'title fourth-animate-2 animate__animated'}>
                    <div className={'text'}>
                        BitLifes power {innerWidth <= 750 ? <br/> : null} Cellula's  {innerWidth > 750 ? <br/> : null}
                        <span>vPOW</span> for {innerWidth <= 750 ? <br/> : null} fair{innerWidth > 750 ? <br/> : null}
                        asset distribution！
                    </div>
                </div>
                <div className={'tag-container fourth-animate-3 animate__animated'}>
                    {tags.map((item) => (
                        <div key={item} className={'tag'}>
                            <div className={'icon'}></div>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})