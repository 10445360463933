import "./index.scss"
import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import {gsap} from "gsap";
import tagIcon from "img/secord/tag-icon.png"
import cell1 from "img/secord/cell-1.png"
import cell2 from "img/secord/cell-2.png"
import energy from "img/secord/energy.png"
import robotList from "img/secord/robot-list.png"
import sliding from "img/secord/sliding-block.png"
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import * as echarts from 'echarts';

type ChildProps = {};

export const SecondPage = forwardRef((props: ChildProps, ref) => {
    const tagList = ['Permissionless', 'Competitiveness', 'Transparency', 'Gamified Issuance']
    const [size, setSize] = useState({
        top1: '0px',
        left1: '0px',
        top2: '0px',
        left2: '0px',
    });
    const requestRef: any = useRef();
    const animateLineRef:any = useRef();
    const getEcharts = () => {
        // 初始化 ECharts 实例
        var chart:any = echarts.init(document.getElementById('chart'));
        // 配置 ECharts
        var option = {
            grid: {
                top: '3%',
                left: '22%',
                right: '7%',
                bottom: '15%',
                containLabel: false
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    //坐标轴轴线设置
                    show: true,
                    lineStyle: {
                        color: '#39F'
                    },
                    symbol: ['none', 'arrow']
                },
                axisLabel: {
                    // 坐标轴刻度文字的设置
                    show: true,
                    fontFamily: 'Snasm',
                    fontSize: 12,
                    color: '#337FC4', // 标签的颜色设置,默认取轴线的颜色
                },
                axisTick: {
                    // 坐标轴刻度设置
                    alignWithLabel: false,
                    length: 0
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(51, 153, 255, 0.10)',
                        width: 1,
                    }
                },
                data: ['1D', '2D', '3D', '4D', '5D', '6D', '7D']
            },
            yAxis: {
                type: 'value',
                scale: true,
                min: 0, // 设置 Y 轴的最小值
                max: 100000, // 设置 Y 轴的最大值
                interval: 25000, // 设置刻度间隔为 10
                axisLabel: {
                    // 坐标轴刻度文字的设置
                    show: true,
                    fontFamily: 'Snasm',
                    fontSize: 12,
                    color: '#FFC75A', // 标签的颜色设置,默认取轴线的颜色
                    // padding: [0, 10, 0, 0]
                },
                axisLine: {
                    //坐标轴轴线设置
                    show: true,
                    lineStyle: {
                        color: '#39F'
                    },
                    symbol: ['none', 'arrow']
                },
                splitLine: {
                    show: false,
                },
            },
            series: [
                {
                    data: [15000, 33000, 52000, 61000, 73000, 84000, 96000],
                    type: 'line',
                    symbol: 'circle', // 设置为圆形
                    symbolSize: 10, // 设置大小
                    itemStyle: {
                        color: '39F' // 设置颜色
                    },
                    areaStyle: { //区域填充样式
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: "rgba(51, 153, 255, 0.20)" },
                                { offset: 1, color: "rgba(51, 153, 255, 0.00)" },
                            ]),
                        },
                    },
                }
            ],
            animationDuration: 2000,
        };
        chart.setOption(option);
        // 监听窗口的 resize 事件，调整图表大小
        window.addEventListener('resize', () => {
            chart.resize();
        });
    }
    const handleResize = () => {
        if (window.innerWidth > 650) {
            requestRef.current = {
                top1: '41.3%',
                left1: '19%',
                top2: '83.5%',
                left2: '59%',
            }
        } else {
            requestRef.current = {
                top1: '24.3%',
                left1: '24.2%',
                top2: '66.2%',
                left2: '62%',
            }
        }
    };
    useImperativeHandle(ref, () => ({
        startAnimation(){
            if (animateLineRef.current) {
                animateLineRef.current.restart()
                return
            }
            // 注册插件
            gsap.registerPlugin(MotionPathPlugin);
            animateLineRef.current = gsap.timeline();
            const mark = document.querySelectorAll('.mark')
            // 先把innerWord透明度置为0，等中间文字动画完成在把透明度置为1，因为如果直接在css样式里置为0，在鼠标移动圆圈的时候会有问题，移动完之后圆圈归位时文字不展示了
            animateLineRef.current.to(mark, {
                width: 0,
                duration: 0.5,
                ease: 'easeInOut',
            }); // "<"在上个动画开始，">"在上个动画结束
            // 添加 flipInX 动画
            // const centerWord = document.querySelectorAll('.center-word')
            // animateLineRef.current.fromTo(centerWord,
            //     { rotationY: -90, opacity: 0 },
            //     { rotationY: 0, opacity: 1, duration: 1, ease: 'back.out(1.7)' }
            // )
            // // // 添加 fadeInUp 动画
            // animateLineRef.current.fromTo(centerWord,
            //     { y: 50, opacity: 0 },
            //     { y: 0, opacity: 1, duration: 1, ease: 'power2.out' },
            //     "<" // 与上一个动画重叠
            // )
            // // 添加 fadeIn 动画
            // animateLineRef.current.fromTo(centerWord,
            //     { opacity: 0 },
            //     { opacity: 1, duration: 1, ease: 'power2.out' },
            //     "<"  // 与上一个动画重叠
            // );

            const tag = document.querySelectorAll('.tag')
            animateLineRef.current.to(tag, {
                opacity: 1,
                y: 0, // Move to original position
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const gridList = document.querySelectorAll('.grid-list')
            animateLineRef.current.to(gridList, {
                opacity: 1,
                x: 0, // Move to original position
                duration: 1,
                ease: 'power2.out'
            }, "<"); // "<"在上个动画开始，">"在上个动画结束
            const cell = document.querySelectorAll('.cell')
            animateLineRef.current.to(cell, {
                opacity: 1,
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const cell1 = document.querySelectorAll('.cell1')
            const cell2 = document.querySelectorAll('.cell2')
            animateLineRef.current.to([cell2], {
                duration: 1,                // 每个动画持续时间
                y: "10px",                 // 向左移动 10px
                onComplete: function () {    // 动画完成后执行
                    gsap.to([cell2], {
                        duration: 1,            // 每个动画持续时间
                        y: "-10px"               // 向右移动 10px
                    });
                }
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to([cell1], {
                duration: 1,                // 每个动画持续时间
                x: "10px",                 // 向左移动 10px
                onComplete: function () {    // 动画完成后执行
                    gsap.to([cell1], {
                        duration: 1,            // 每个动画持续时间
                        x: "-10px"               // 向右移动 10px
                    });
                }
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(cell1, {
                top: requestRef.current.top1,
                left: requestRef.current.left1,
                duration: 1,
                ease: 'power2.out'
            }, "+=1"); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(cell2, {
                top: requestRef.current.top2,
                left: requestRef.current.left2,
                duration: 1,
                ease: 'power2.out'
            }, "<"); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(gridList, {
                duration: 0.5,                // 动画持续时间
                borderColor: "#3399FF",           // 边框颜色
                repeat: 2,                   // 无限循环
                yoyo: true,                   // 反向播放
                ease: "power1.inOut"
            }, ">").call(() => {
                getEcharts()
            }); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to([cell1, cell2, gridList], {
                opacity: 0,
                duration: 1,
                ease: 'power2.out'
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            const robotImg = document.querySelectorAll('.robot-img')
            const echartsWrap = document.querySelectorAll('.echarts-wrap')
            animateLineRef.current.to([robotImg, echartsWrap], {
                opacity: 1,
                duration: 1,
                ease: 'power2.out'
            }, "<") // "<"在上个动画开始，">"在上个动画结束
            const robot = document.querySelectorAll('.robot')
            animateLineRef.current.to(robot, {
                duration: 2, // 动画持续时间，单位秒
                scale: 0,  // 缩小至原始大小的50%
                x: '40px',
                y: '60px',
                ease: "power1.inOut" // 缓动效果
            }, ">=1.5") // "<"在上个动画开始，">"在上个动画结束
            const energy = document.querySelectorAll('.energy')
            const numWrap = document.querySelectorAll('.num-wrap')
            animateLineRef.current.to(energy, {
                duration: 2, // 动画持续时间，单位秒
                scale: 1,  // 缩小至原始大小的50%
                ease: "power1.inOut" // 缓动效果
            }, "<=0.7") // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(numWrap, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut" // 缓动效果
            }, "<=1") // "<"在上个动画开始，">"在上个动画结束
            const numValue = document.querySelectorAll('.num-value')
            animateLineRef.current.to(numValue, {
                duration: 3,                // 动画持续时间，单位秒
                innerText: 8546515,             // 最终的数字值
                snap: { innerText: 1 },     // 设置数字的递增步长
                ease: "power1.inOut",       // 缓动效果
            }, ">") // "<"在上个动画开始，">"在上个动画结束
            const robotList = document.querySelectorAll('.robot-list')
            animateLineRef.current.to(robotList, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, "<1.5") // "<"在上个动画开始，">"在上个动画结束
            // // Select the slider and path
            const slider1 = document.querySelector(".slider1");
            const path1 = document.querySelector(".path1");
            const slider2 = document.querySelector(".slider2");
            const path2 = document.querySelector(".path2");
            const slider3 = document.querySelector(".slider3");
            const path3 = document.querySelector(".path3");
            const slider4 = document.querySelector(".slider4");
            const path4 = document.querySelector(".path4");
            const slider5 = document.querySelector(".slider5");
            const path5 = document.querySelector(".path5");
            const slider6 = document.querySelector(".slider6");
            const path6 = document.querySelector(".path6");

            const slider = document.getElementsByClassName('.slider');
            const path = document.getElementsByClassName('.path');
            const sliders1 = [slider1, slider3, slider5]
            const paths1 = [path1, path3, path5]

            const sliders2 = [slider2, slider4, slider6]
            const paths2 = [path2, path4, path6]
            console.log(slider, path)
            animateLineRef.current.to(sliders1, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, ">"); // "<"在上个动画开始，">"在上个动画结束
            sliders1.forEach((slider, index) => {
                animateLineRef.current.to(slider, {
                    duration: 3,
                    repeat: -1,
                    ease: "power1.inOut",
                    motionPath: {
                        path: paths1[index],
                        align: paths1[index],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: 180,
                    }
                }, "<");
            });
            animateLineRef.current.to(sliders2, {
                duration: 1, // 动画持续时间，单位秒
                opacity: 1,
                ease: "power1.inOut",       // 缓动效果
            }, "<+1"); // "<"在上个动画开始，">"在上个动画结束
            sliders2.forEach((slider, index) => {
                animateLineRef.current.to(slider, {
                    duration: 3,
                    repeat: -1,
                    ease: "power1.inOut",
                    motionPath: {
                        path: paths2[index],
                        align: paths2[index],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: 180,
                    }
                }, "<");
            });
        },
        removeAnimation(){
            if (animateLineRef.current) {
                animateLineRef.current.kill();
            }
        }
    }))

    useEffect(() => {
        handleResize()
        // 添加窗口尺寸变化的监听器
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className="second-page">
            <div className="left">
                <div className="center-word">
                    <div>A Programmable</div>
                    <div>Incentive Layer for</div>
                    <div>Fair Asset Distribution</div>
                    <div>and Liquidity Guidance</div>
                    <div className="mark-list">
                        <div className="mark-wrap">
                            <div className="mark mark1"></div>
                        </div>
                        <div className="mark-wrap">
                            <div className="mark mark2"></div>
                        </div>
                        <div className="mark-wrap">
                            <div className="mark mark3"></div>
                        </div>
                    </div>
                </div>

                <div className="tag-list">
                    {
                        tagList.map((tag: any, index: number) => (
                            <div className="tag" key={index}>
                                <img src={tagIcon} alt=""/>
                                <div className="label">{tag}</div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="right">
                <div className="cell-list">
                    <img className="cell cell1" src={cell1} alt=""/>
                    <div className="grid-list">
                        {
                            Array.from({length: 9}).map((grid: any, index: number) => (
                                <div className={`single-grid grid-${index + 1}`} key={index}>

                                </div>
                            ))
                        }
                    </div>
                    <img className="cell cell2" src={cell2} alt=""/>
                </div>

                <div className="robot">
                    <div className="robot-img"></div>
                    <div className="echarts-wrap">
                        <div className="title1">Hashrate Curve</div>
                        <div className="title2">Hashrate</div>
                        <div className="echarts">
                            <div className="chart" id="chart"></div>
                        </div>
                    </div>
                </div>

                <div className="energy-block">
                    <div className="num-wrap">
                        <div className="num-value">0</div>
                    </div>
                    <img className="energy" src={energy} alt=""/>
                    <div className="robot-list">
                        <img className="robot-list-img" src={robotList} alt=""/>
                        <div className="svg-wrap">
                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path1" id="path" d="M210,100 L210,150 L45,150 L45,120" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider1" alt="Slider"/>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path2" id="path"
                                      d="M250,125 L250,220 L190,220 L190,320 L118,320 L118,287" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider2" alt="Slider"/>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path3" id="path"
                                      d="M300,145 L300,408 L254,408 L254,388" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider3" alt="Slider"/>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path4" id="path"
                                      d="M350,145 L350,408 L404,408 L404,388" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider4" alt="Slider"/>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path5" id="path"
                                      d="M400,125 L400,220 L460,220 L460,320 L538,320 L538,287" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider5" alt="Slider"/>

                            <svg className="path-svg" width="100%" height="100%" viewBox="0 0 660 410">
                                <path className="path path6" id="path"
                                      d="M440,100 L440,150 L620,150 L620,120" fill="none"
                                      stroke-width="2" stroke="#323232"/>
                            </svg>
                            <img src={sliding} className="slider slider6" alt="Slider"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
})