import "./index.scss"
import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import logoImg from "img/first/logo.png"
import arrowImg from "img/first/arrow.png"
import redIcon from "img/first/red-decorate.png"
import greenIcon from "img/first/green-decorate.png"
import {gsap} from 'gsap';
import {BgPage} from "src/view/first/bg.tsx";

type ChildProps = {};

export const FirstPage = forwardRef((props: ChildProps, ref) => {
    const [coords, setCoords] = useState({x: 0, y: 0});
    const [hovered, setHovered] = useState(false);
    const requestRef: any = useRef();
    const animateLineRef:any = useRef();
    const handleMouseMove = useCallback((e: any) => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
        const handleUpdate = () => {
            const rect = e.target.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const x = e.clientX - centerX;
            const y = e.clientY - centerY;
            setCoords({x, y});
        };
        requestRef.current = requestAnimationFrame(handleUpdate);
    }, []);

    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => {
        setHovered(false);
        setCoords({x: 0, y: 0});
    };
    useImperativeHandle(ref, () => ({
        startAnimation(){
            if (animateLineRef.current) {
                animateLineRef.current.restart()
                return
            }
            animateLineRef.current = gsap.timeline();
            const list = document.querySelectorAll('.letter')
            const innerCircle = document.querySelectorAll('.inner-circle')
            const innerWord = document.querySelectorAll('.inner-word')
            console.log(innerWord)
            // 先把innerWord透明度置为0，等中间文字动画完成在把透明度置为1，因为如果直接在css样式里置为0，在鼠标移动圆圈的时候会有问题，移动完之后圆圈归位时文字不展示了
            animateLineRef.current.to(innerWord, {
                opacity: 0,
                duration: 0.01,
                ease: 'easeInOut'
            }); // "<"在上个动画开始，">"在上个动画结束
            document.querySelectorAll('.letter').forEach((letter, index) => {
                animateLineRef.current.to(letter, {
                    opacity: 1,
                    // y: 0, // Move to original position
                    duration: 0.3,
                    ease: 'power2.out'
                }, index * 0.2); // Stagger each letter's animation
            });

            const box = document.querySelectorAll('.box')
            const upDown = document.querySelectorAll('.upDown')
            const leftAndRight = document.querySelectorAll('.leftAndRight')
            animateLineRef.current.to(box, {
                duration: 1,
                opacity: 1,
                ease: 'easeInOut'
            }, '+=0.1');
            animateLineRef.current.to(upDown, {
                y: -10, // Move up by 10px
                duration: 1,
                yoyo: true, // Makes it go back to the original position
                repeat: -1, // Repeats indefinitely
                ease: 'easeInOut'
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            animateLineRef.current.to(leftAndRight, {
                x: -10, // Move up by 10px
                duration: 1,
                yoyo: true, // Makes it go back to the original position
                repeat: -1, // Repeats indefinitely
                ease: 'easeInOut'
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
            // 先是边框旋转两圈，然后边框消失，文字和圈出现
            const spinner = document.querySelectorAll('.spinner')
            // animateLineRef.current.to(spinner, {
            //     opacity: 1,
            //     duration: 1, // 动画持续时间
            //     rotation: 720, // 旋转两圈
            //     ease: "linear", // 线性过渡
            //     repeat: 0, // 不重复
            // }, '<');
            animateLineRef.current.to(spinner, {
                opacity: 0,
                duration: 0.01, // 动画持续时间
                ease: "linear", // 线性过渡
                repeat: 0, // 不重复
            }, '>');
            animateLineRef.current.to([innerCircle, innerWord], {
                opacity: 1,
                duration: 1,
                ease: 'easeInOut'
            }, '<'); // "<"在上个动画开始，">"在上个动画结束
        },
        removeAnimation(){
            if (animateLineRef.current) {
                animateLineRef.current.kill();
            }
        }
    }))
    return (
        <div className={'first-page'}>
            <div className={'bg-wrap'}>
                <BgPage></BgPage>
            </div>
            <div className={'logo-wrap'}>
                <img src={logoImg} className={'logo'} alt=""/>
            </div>
            <div className={'first-content'}>
                <div className={'center-word'}>
                    <div className={'top-word'}>
                        <div className={'yellow'}>
                            {/*{*/}
                            {/*    'Cellula'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>Cellula</span>
                            <span>&nbsp;</span>
                        </div>
                        <div className={'white'}>
                            {/*{*/}
                            {/*    'is'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>is</span>
                            <span>&nbsp;</span>
                            {/*{*/}
                            {/*    'pioneering'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>pioneering</span>
                            <span>&nbsp;</span>
                            {/*{*/}
                            {/*    'a'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>a</span>
                        </div>
                    </div>
                    <div className={'bottom-word'}>
                        <div className={'white'}>
                            {/*{*/}
                            {/*    'programmable'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>programmable</span>
                        </div>
                        <span>&nbsp;</span>
                        <div className={'blue'}>
                            {/*{*/}
                            {/*    'incentive'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>incentive</span>
                        </div>
                        <span>&nbsp;</span>
                        <div className={'white'}>
                            {/*{*/}
                            {/*    'layer'.split('').map((item: any, index: number) => (*/}
                            {/*        <span key={index} className={`letter c-${index + 1}`}>{item}</span>*/}
                            {/*    ))*/}
                            {/*}*/}
                            <span className={'letter'}>layer</span>
                        </div>
                    </div>
                </div>

                <div className={'box upDown conway'} onClick={() => (window as any)["fullpage_api"].moveTo(5)}>Conway's Game of Life</div>
                <div className={'box leftAndRight minting'}>Mining Algorithm</div>
                <div className={'box upDown virtual'} onClick={() => (window as any)["fullpage_api"].moveTo(2)}>virtual Proof-of-Work (vPOW)</div>
                <div className={'box leftAndRight game'}>Game Theory</div>
                <div className={'box upDown pricing'} onClick={() => (window as any)["fullpage_api"].moveTo(4)}>Pricing Algorithm — Variable Rate GDAs</div>
                <div className={'box leftAndRight consensus'} onClick={() => (window as any)["fullpage_api"].moveTo(3)}>Consensus Algorithm — Analysoor</div>
                <img className={'box leftAndRight upDown redIcon'} src={redIcon} alt=""/>
                <img className={'box leftAndRight greenIcon'} src={greenIcon} alt=""/>
            </div>

            <div className={'first-footer'}>
                <div
                    className="outer-circle"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                >
                    <div
                        className="inner-circle circle1"
                        style={{
                            transform: hovered
                                ? `translate(${coords.x * 1}px, ${coords.y * 1}px)`
                                : 'none',
                        }}
                    >
                    </div>
                    <div className="spinner"></div>
                    {
                        hovered ?
                            <img
                                className="arrow-img" src={arrowImg} alt=""
                                style={{
                                    transform: hovered
                                        ? `translate(${coords.x * 0.8}px, ${coords.y * 0.8}px)`
                                        : 'none',
                                }}/>
                            :
                            <div className="inner-word">
                                {/*<img*/}
                                {/*    className="arrow-img1" src={arrowImg} alt=""*/}
                                {/*    />*/}
                                <p>understand</p>
                                <p>cellula</p>
                            </div>
                    }
                    <div
                        className="inner-circle circle2"
                        style={{
                            transform: hovered
                                ? `translate(${coords.x * 0.5}px, ${coords.y * 0.5}px)`
                                : 'none',
                        }}
                    />
                </div>
            </div>
        </div>
    );
})